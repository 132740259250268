<script lang="js">
import Menu from './Menu.vue';
import { mapGetters } from "vuex";

export default {
  name: 'TheMainMenu',
  extends: Menu,
  computed: {
    ...mapGetters("profile", ["can"]),
    items() {
      const items = [
        {
          icon: 'fa-briefcase',
          title: this.$tc('client.title', 2),
          route: { name: 'client.index' },
          permission: "client.read",
        },
        {
          icon: 'fa-plug',
          title: this.$tc('connector.title', 2),
          route: { name: 'connector.index' },
          permission: "client.connector.read",
        },
        {
          icon: 'fa-key',
          title: this.$tc('token.title', 2),
          route: { name: 'token.index' },
          permission: "api-token.read",
        },
        {
          icon: 'fa-key',
          title: this.$tc('apiCredential.title', 2),
          route: { name: 'apiCredentials.index' },
          permission: "api-credential.read",
        },
        {
          icon: 'fa-cog',
          title: this.$tc('instruction.instructionTypeTitles.orderAdditions', 2),
          route: { name: 'instruction.orderAdditions' },
          permission: "instruction.read",
        },
        {
          icon: 'fa-cog',
          title: this.$tc('instruction.instructionTypeTitles.complimentCard', 2),
          route: { name: 'instruction.complimentCards' },
          permission: "compliment-card.read",
        },
      ];
      return items.filter(item => this.can(item.permission));
    },
  },
};
</script>
